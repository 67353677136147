import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Resource from '../../components/Resource';
import { Link } from 'gatsby';

const CapstoneProjectPage = () => {
  return (
    <Layout title="Capstone Project">
      <Section className="capstone-project__header-section">
        <Container fullWidth>
          <div className="capstone-project__header-breadcrumb">
            <Link
              className="capstone-project__header-breadcrumb-link"
              to="/classroom-instruction"
            >
              Introduction
            </Link>
            <Icon
              name="caratright"
              marginLeft
              marginRight
              className="capstone-project__header-breadcrumb-link"
            />
            <span>Capstone Course</span>
          </div>
        </Container>
        <div className="capstone-project__header-container">
          <p className="capstone-project__header-text">Capstone Year</p>
          <p style={{ textAlign: 'center' }}>
            The Futurelab+ capstone is a culminating year designed to support
            students’ entrepreneurial drive, creativity, and innovative spirit.
            Students will explore and research a topic about which they are
            passionate, applying the knowledge they gained from Genentech’s
            Futurelab+ syllabi.
          </p>
        </div>
      </Section>
      <Section className="capstone-project__resource-section">
        <Container>
          <Row>
            <h3 style={{ fontWeight: '700' }}>
              Project Starters, Project Exemplars, Journal, Rubric, and more
            </h3>
            <p>
              All the materials necessary to guide students through a capstone
              project which brings together all their Futurelab+ learnings.
            </p>
          </Row>
        </Container>
      </Section>
      <Section className="capstone-project__resource-section">
        <Container>
          <Row>
            <Column size={6}>
              <h3>Project Resources</h3>
            </Column>
            <Column
              size={6}
              className="capstone-project__complete-download-container"
            >
              <Button to="/pdfs/capstone-project/Capstone-complete-set.zip">
                <Icon name="download" marginRight />
                Complete Set
              </Button>
            </Column>
          </Row>
          <Row>
            <Column size={3}>
              <Resource
                tophat="a"
                tophatClass="capstone-project__resource-tophat"
                subject="Capstone"
                title="Project Management Guide"
                titleClass="capstone-project__resource-title"
                description="This project management guide will help capstone educators frame student project milestones and expectations in a given school year. "
                asCard
                actions={[
                  {
                    label: 'Project Guide',
                    type: 'download',
                    path:
                      '/pdfs/capstone-project/gen-capstone-projectedmanagementguide.pdf',
                    size: '.PDF, 1.5MB',
                  },
                ]}
              />
            </Column>
            <Column size={3}>
              <Resource
                tophat="a"
                tophatClass="capstone-project__resource-tophat"
                subject="Capstone"
                title="Student Capstone Journal"
                titleClass="capstone-project__resource-title"
                description="This student capstone journal will serve as a guide to help students meet the expectations and goals of their project and as a record of their work throughout the capstone experience. "
                asCard
                actions={[
                  {
                    label: 'Student Journal',
                    type: 'download',
                    path: '/pdfs/capstone-project/gen-capstone-journal.pdf',
                    size: '.PDF, 590KB',
                  },
                ]}
              />
            </Column>
            <Column size={3}>
              <Resource
                tophat="a"
                tophatClass="capstone-project__resource-tophat"
                subject="Capstone"
                title="Project Starters"
                titleClass="capstone-project__resource-title"
                description="Find thirteen in-class ideas for students. Ideas include microbial bioremediation, antibiotic sensitivity testing, biomedical engineering design challenge and many more!"
                asCard
                actions={[
                  {
                    label: 'Project Starter',
                    type: 'download',
                    path: '/pdfs/capstone-project/Capstone-ProjectStarters.pdf',
                    size: '.PDF, 40KB',
                  },
                ]}
              />
            </Column>
            <Column size={3}>
              <Resource
                tophat="a"
                tophatClass="capstone-project__resource-tophat"
                subject="Capstone"
                title="Capstone Rubric"
                titleClass="capstone-project__resource-title"
                description="This rubric is designed to evaluate the student capstone project, serving as a guide to evaluate how students meet the expectations and goals of their project."
                asCard
                actions={[
                  {
                    label: 'Rubric',
                    type: 'download',
                    path: '/pdfs/capstone-project/gen-capstone-rubrics.pdf',
                    size: '.PDF, 76KB',
                  },
                ]}
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="capstone-project__resource-section">
        <Container>
          <Row>
            <Column size={12}>
              <h3>Project Exemplars</h3>
              <p>
                Example project ideas to get students thinking about and
                preparing for their own capstone year.
              </p>
            </Column>
          </Row>
          <Row>
            <Column size={3}>
              <Resource
                tophat="Unit 1"
                tophatClass="capstone-project__resource-tophat-text-visible"
                subject="Capstone Exemplar"
                title="Innovating Biotech"
                titleClass="capstone-project__resource-title"
                description="Students explore the creation of a wearable tech prototype and a video presentation showing how this technology could help patients by using biomarkers as indicators of health."
                asCard
                actions={[
                  {
                    label: 'Project Exemplars',
                    type: 'download',
                    path:
                      '/pdfs/capstone-project/gen-projectexemplars-bm-unit1.pdf',
                    size: '.PDF, 1MB',
                  },
                ]}
              />
            </Column>
            <Column size={3}>
              <Resource
                tophat="Unit 2"
                tophatClass="capstone-project__resource-tophat-text-visible"
                subject="Capstone Exemplar"
                title="Health Equity"
                titleClass="capstone-project__resource-title"
                description="Students explore how education and access to healthcare impact health outcomes."
                asCard
                actions={[
                  {
                    label: 'Project Exemplars',
                    type: 'download',
                    path:
                      '/pdfs/capstone-project/gen-projectexemplars-bm-unit2.pdf',
                    size: '.PDF, 1MB',
                  },
                ]}
              />
            </Column>
            <Column size={3}>
              <Resource
                tophat="Unit 3"
                tophatClass="capstone-project__resource-tophat-text-visible"
                subject="Capstone Exemplar"
                title="Innovating Disease Treatments"
                titleClass="capstone-project__resource-title"
                description="Students explore the research and collaboration by doctors and biomedical scientists in discovering the cellular mechanisms that threaten human health."
                asCard
                actions={[
                  {
                    label: 'Project Exemplars',
                    type: 'download',
                    path:
                      '/pdfs/capstone-project/gen-projectexemplars-bm-unit3.pdf',
                    size: '.PDF, 1MB',
                  },
                ]}
              />
            </Column>
            <Column size={3}>
              <Resource
                tophat="Unit 4"
                tophatClass="capstone-project__resource-tophat-text-visible"
                subject="Capstone Exemplar"
                title="Scientific Breakthroughs"
                titleClass="capstone-project__resource-title"
                description="Students example the role AI plays in the future of developing longevity enhancing drugs and how education can be leveraged to impact healthy decision making to improve life expectancy."
                asCard
                actions={[
                  {
                    label: 'Project Exemplars',
                    type: 'download',
                    path:
                      '/pdfs/capstone-project/gen-projectexemplars-bm-unit4.pdf',
                    size: '.PDF, 76KB',
                  },
                ]}
              />
            </Column>
          </Row>
          <Row>
            <Column size={3}>
              <Resource
                tophat="Unit 5"
                tophatClass="capstone-project__resource-tophat-text-visible"
                subject="Capstone Exemplar"
                title="Solution Seeking Microbes"
                titleClass="capstone-project__resource-title"
                description="Students explore innovation of new biotechnological tools to change the way we fight deadly pathogens and to treat or prevent genetic diseases."
                asCard
                actions={[
                  {
                    label: 'Project Exemplars',
                    type: 'download',
                    path:
                      '/pdfs/capstone-project/gen-projectexemplars-ag-unit5.pdf',
                    size: '.PDF, 1MB',
                  },
                ]}
              />
            </Column>
            <Column size={3}>
              <Resource
                tophat="Unit 6"
                tophatClass="capstone-project__resource-tophat-text-visible"
                subject="Capstone Exemplar"
                title="Alternative Proteins"
                titleClass="capstone-project__resource-title"
                description="Students explore how genetic engineering creates new solutions in farming, health, medicine, research and conservation."
                asCard
                actions={[
                  {
                    label: 'Project Exemplars',
                    type: 'download',
                    path:
                      '/pdfs/capstone-project/gen-projectexemplars-ag-unit6.pdf',
                    size: '.PDF, 1MB',
                  },
                ]}
              />
            </Column>
            <Column size={3}>
              <Resource
                tophat="Unit 7"
                tophatClass="capstone-project__resource-tophat-text-visible"
                subject="Capstone Exemplar"
                title="Plant to Pharmaceutical"
                titleClass="capstone-project__resource-title"
                description="Students explore high biodiversity areas that potentially contain many other plants with medicinal properties that are undiscovered by modern medicine."
                asCard
                actions={[
                  {
                    label: 'Project Exemplars',
                    type: 'download',
                    path:
                      '/pdfs/capstone-project/gen-projectexemplars-ag-unit7.pdf',
                    size: '.PDF, 1MB',
                  },
                ]}
              />
            </Column>
            <Column size={3}>
              <Resource
                tophat="Unit 8"
                tophatClass="capstone-project__resource-tophat-text-visible"
                subject="Capstone Exemplar"
                title="Community Science"
                titleClass="capstone-project__resource-title"
                description="Students will consider how a community can implement a data collection plan using appropriate DNA ID techniques that can be used to amplify or sequence the DNA."
                asCard
                actions={[
                  {
                    label: 'Project Exemplars',
                    type: 'download',
                    path:
                      '/pdfs/capstone-project/gen-projectexemplars-ag-unit8.pdf',
                    size: '.PDF, 1MB',
                  },
                ]}
              />
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default CapstoneProjectPage;
